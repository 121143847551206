import { SHOW_SUCCESS, SHOW_ALERT, CLEAR_ALERT, CLEAR_SUCCESS } from "../types";
import moment from "moment";
import { Capacitor } from "@capacitor/core";

moment.locale("es", {
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
});

export const API_KEY = "AIzaSyD_0F-t4VQvP9WHWcb7-E90M9WGzWHLUbU";

export const VALIDATOR_URL =
  "https://validator.iaptic.com/v3/validate?appName=com.musclefatory.byvogel&apiKey=9e6dcffd-c05a-429d-b89e-1e0b1befe2cf";

export const CONEKTA_API_KEY =
  process.env.NODE_ENV === "development"
    ? "key_CrAb2j5eNd4g9GBWHszJjYA"
    : "key_aFqb9ciyX7Bkp8JTTMPsqmQ";

export const stripe_test_key =
  "pk_test_51M4nTTKh7LplQM75F4oGsIDIpbBM5VBMKmYRH6yodAqEcSL3Z8xHdmKX7xRYRUdvoqnr9buhAyjdbWjOXruyyJWE00N2UR9jyv";

export const stripe_live_key =
  "pk_live_51M4nTTKh7LplQM75JKKWy2NnKX1A3iFR7SB18qc26UYMeQeyin2cg96v1YtUATmebXVVi7a2TrW5UQtNHHLRcZyD00bMmvJBxN";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_test_key : stripe_live_key;

export const displaySuccess = (dispatch, message) => {
  dispatch({ type: SHOW_SUCCESS, payload: message });
  setTimeout(() => {
    dispatch({ type: CLEAR_SUCCESS });
  });
};

export const displayError = (dispatch, error) => {
  if (typeof error === "object") {
    error = error.toString();
  }
  dispatch({ type: SHOW_ALERT, payload: error });
  setTimeout(() => dispatch({ type: CLEAR_ALERT }), 3000);
};

export const BASE_URL =
  (process.env.NODE_ENV === "development" && Capacitor.getPlatform() === "web"
    ? "http://localhost:4000"
    : "https://musclefactorybyvogel.com") + "/api";

export const S3_ENDPOINT = "https://musclefactory.s3.us-west-1.amazonaws.com";

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `&${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.style.visibility = "hidden";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const misclases = [
  {
    name: "Online",
    handle: "/myfactory/online",
  },
  {
    name: "Presenciales",
    handle: "/myfactory/presenciales",
  },
];

export const shop = [
  {
    name: "Clases",
    handle: "/myfactory/shop",
  },
  {
    name: "Merch",
    handle: "/myfactory/merch",
  },
];

export const cuenta = [
  {
    name: "Mi Información",
    handle: `/myfactory/informacion`,
  },
  {
    name: "Mis Métodos de Pago",
    handle: `/myfactory/metodos-pago`,
  },
  {
    name: "Mis Compras",
    handle: `/myfactory/pagos`,
  },
];

export const cardStyle = {
  style: {
    base: {
      color: "#f8f9fa",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#f8f9fa",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";

export const categories = {
  dance: [11, 12, 13, 15],
  workout: [14, 15],
  presenciales: [11742, 11743, 11744, 11745],
};

export const getCompradasPresenciales = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter((class_category) =>
    package_ids.includes(class_category.package_class_id)
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getVigencia = (handle, user) => {
  const paquete = user.vigencias.find(
    (class_category) => class_category.handle === handle
  );
  if (paquete) return paquete.expiration;
};

export const isManager = (user) => {
  return user.isManager !== null && user.isManager;
};

export const isAdmin = (user) => {
  return user.isAdmin !== null && user.isAdmin;
};

export const isCoach = (user) => {
  return user.instructor_id !== null && user.instructor_id;
};

export const getLocalMoment = (datetime) => {
  return moment(
    moment(datetime).utc().format("YYYY-MM-DD HH:mm:ss"),
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const getClassAvailableSpaces = (singleClass) => {
  return singleClass.capacity - singleClass.class_reservations.length;
};

export const userInformatinCompleted = (user) => {
  let valid = true;
  if (String(user.phone).length < 10) valid = false;
  if (user.last_name === "" || user.last_name === null) valid = false;
  return valid;
};

export const getLocalMomentDiff = (datetime, unitOfTime) => {
  return moment().diff(getLocalMoment(datetime), unitOfTime);
};

export const hasNotch = (device) => {
  let current = String(device);
  if (current.includes("iPhone")) {
    return (
      current.includes("X") ||
      current.includes("11") ||
      current.includes("12") ||
      current.includes("13") ||
      current.includes("14") ||
      current.includes("15")
    );
  }
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const getBilling = (class_package) => {
  const { is_subscription, subscription_period, subscription_interval } =
    class_package;
  let period = subscription_period;
  let interval = subscription_interval;
  if (is_subscription) {
    if (subscription_interval > 1) {
      period =
        subscription_period === "month"
          ? "meses"
          : subscription_period === "year"
          ? "años"
          : "días";
    } else {
      period =
        subscription_period === "month"
          ? "mes"
          : subscription_period === "year"
          ? "año"
          : "día";
    }
  }
  let frequency = "cada ";
  if (interval === 1) {
    interval = "";
  }
  return `${frequency} ${interval} ${period}`;
};

export const getCurrentPrice = (class_package, platform) => {
  if (platform === "ios") return class_package.apple_product_price;
  const { sale_price, price } = class_package;
  let current_price = sale_price !== null ? sale_price : price;
  return current_price;
};

export const getMonthlyPrice = (class_package, platform) => {
  let { subscription_period, subscription_interval } = class_package;
  if (subscription_period === "year") {
    subscription_interval += 12;
  }
  const current_price = getCurrentPrice(class_package, platform);
  return parseFloat(current_price / subscription_interval).toFixed(2);
};

export const getSavings = (class_package, monthlyPackage, platform) => {
  const monthlyPrice = getMonthlyPrice(class_package, platform);
  const regularMonthPrice = getCurrentPrice(monthlyPackage, platform);
  let { subscription_period, subscription_interval } = class_package;
  if (subscription_period === "year") {
    subscription_interval += 12;
  }
  const regularPrice = regularMonthPrice * subscription_interval;
  const savingsPrice = monthlyPrice * subscription_interval;
  return parseFloat(regularPrice - savingsPrice).toFixed(2);
};
