import React, { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { PurchasesContext } from "../../context/PurchasesContext";

const CancelForm = ({ purchase }) => {
  const [reason, setReason] = useState("");

  const { spinner, cancelSubscription } = useContext(PurchasesContext);

  const { alert } = useContext(ModalContext);

  const handleSubmit = () => {
    if (reason === "" || reason.length < 10) {
      return alert("Debes indicar un motivo de cancelación.");
    }
    cancelSubscription(purchase.purchase_id, reason);
  };

  return (
    <div>
      <p>
        ¿Estás seguro que deseas cancelar tu suscripción #{purchase.purchase_id}{" "}
        a {purchase.class_package.title}?
      </p>{" "}
      <p className="small">
        Si estás en período de prueba gratis perderás acceso a tus beneficios
        inmediatamente.
      </p>
      <p className="small">
        De lo contrario, perderás acceso a tus beneficios cuando termine tu
        ciclo actual de facturación.{" "}
      </p>
      <p className="small">
        No se hará ningún reembolso por el último cargo efectuado aún y cuando
        solo haya pasado un minuto de haberse realizado.
      </p>
      <p>Esta acción NO puede deshacerse,</p>
      <label>¿Cuál es el motivo de tu cancelación?</label>
      <input
        type="text"
        value={reason}
        className="form-control mb-3"
        onChange={(e) => setReason(e.target.value)}
      />
      <button
        disabled={spinner}
        onClick={handleSubmit}
        className="btn btn-danger btn-small"
      >
        {spinner ? (
          <div className="spinner-border"></div>
        ) : (
          "Entiendo, Cancelar Mi Membresía"
        )}
      </button>
    </div>
  );
};

export default CancelForm;
