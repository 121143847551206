import React, { useContext } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import { formatMonto, getBilling, getSavings } from "../../utils";
import { PurchasesContext } from "../../context/PurchasesContext";
import { PackagesContext } from "../../context/PackageContext";
import { ModalContext } from "../../context/ModalContext";
import { Glassfy } from "capacitor-plugin-glassfy";
import { Link } from "@reach/router";
import moment from "moment";

const PricingCard = ({ class_package, current }) => {
  const { platform } = useContext(CapacitorContext);
  const { createPurchaseAppStore } = useContext(PurchasesContext);

  const { paquetes } = useContext(PackagesContext);

  const { alert } = useContext(ModalContext);

  const { class_package_id } = class_package;

  const handleSubmit = (e) => {
    e.preventDefault();
    Glassfy.purchaseSku({ sku: class_package.sku })
      .then(handlePurchase)
      .catch(handleError);
  };

  const handleError = (response) => {
    alert(response);
  };

  const handlePurchase = () => {
    createPurchaseAppStore(class_package_id);
  };

  const renderPricing = (class_package) => {
    if (platform === "ios") {
      return <h4>${formatMonto(class_package.apple_product_price)} MXN</h4>;
    }
    const { price, sale_price } = class_package;
    if (sale_price && sale_price !== null && sale_price > 0) {
      return (
        <div>
          <p className="ms-2 small text-accent strikethrough">
            ${formatMonto(price)} MXN
          </p>
          <h4 className="mb-4">${formatMonto(sale_price)} MXN</h4>
        </div>
      );
    }
    return <h4>${formatMonto(price)} MXN</h4>;
  };

  const renderMonthly = () => {
    if (class_package && class_package !== null) {
      const monthlyPackage = paquetes.find(
        ({ subscription_interval, subscription_period }) =>
          subscription_interval === 1 && subscription_period === "month"
      );
      if (monthlyPackage) {
        const { subscription_period, subscription_interval } = class_package;
        if (
          (subscription_period !== "day" && subscription_interval > 1) ||
          subscription_period === "year"
        ) {
          let savings = getSavings(class_package, monthlyPackage, platform);
          return (
            <p className="small text-primary">
              Ahorra ${formatMonto(savings)} MXN
            </p>
          );
        }
      }
    }
  };

  const renderFreeTrial = () => {
    if (class_package && class_package !== null) {
      if (class_package.free_trial_length !== null) {
        return (
          <p className="small text-warning">
            Prueba Gratis de {class_package.free_trial_length} días
          </p>
        );
      }
      if (class_package.free_trial_end !== null) {
        return (
          <p className="small text-warning">
            Prueba Gratis hasta el
            {moment(class_package.free_trial_end).utc().format("DD MMM YYYY")}
          </p>
        );
      }
    }
  };

  const renderButton = () => {
    if (current && current !== null) {
      if (current.class_package_id === class_package.class_package_id) {
        return (
          <button className="btn btn-outline-primary w-100" disabled>
            TU PLAN ACTUAL
          </button>
        );
      }
    }
    if (platform === "ios") {
      return (
        <button
          disabled={!class_package.sku}
          className="btn btn-primary w-100"
          onClick={handleSubmit}
        >
          {!class_package.sku ? (
            <div className="spinner-border"></div>
          ) : (
            "SUSCRIBIRME"
          )}
        </button>
      );
    }
    return (
      <Link
        to={`/checkout/${class_package.class_package_id}`}
        className="btn btn-primary w-100"
      >
        SUSCRÍBETE
      </Link>
    );
  };

  return (
    <div className="card my-3">
      <h4>{class_package.title}</h4>
      <p className="small">
        Acceso 24/7 a todas las clases: Cardio, Fuerza, Yoga, Barre, HIIT y más.
      </p>
      <h5>Cargo {getBilling(class_package)}</h5>
      {renderPricing(class_package)}
      {renderMonthly()}
      {renderFreeTrial()}
      {renderButton()}
    </div>
  );
};

export default PricingCard;
