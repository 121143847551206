import { Link, navigate } from "@reach/router";
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { S3_ENDPOINT } from "../utils";

const SignUp = () => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [telefono, setTelefono] = useState("");
  const { user, spinner, signUp } = useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      return alert("Las contraseñas no coinciden");
    }
    if (!nombre || nombre === "") return alert("Ingresa tu nombre");
    if (!email || email === "") return alert("Ingresa tu correo");
    if (!telefono || telefono === "") return alert("Ingresa tu telefono");
    signUp(nombre, email, password, telefono);
  };

  useEffect(() => {
    if (user !== null) {
      navigate(`/myfactory`);
    }
  }, [user]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 bg-black vh-100 px-0 hide-mobile">
          <div className="bg-vertical"></div>
        </div>
        <div className="col-12 col-md-6 bg-camo vh-100">
          <div className="row align-items-center vh-100">
            <div className="login-card">
              <img
                src={`${S3_ENDPOINT}/logo-blanco.png`}
                className="w-100 mb-4 cursor-pointer"
                alt="MUSCLE FACTORY Logo"
                onClick={() => navigate("/")}
              />
              <h1 className="text-center text-white my-4">JOIN THE FACTORY</h1>
              <div
                id="login-card"
                className="card bg-gray text-white no-scale text-left shadow p-4"
              >
                <form onSubmit={handleSubmit}>
                  <label>Nombre</label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                  />
                  <label>Correo Electrónico</label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>Contraseña</label>
                  <input
                    type="password"
                    className="form-control mb-3"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label>Confirmar Contraseña</label>
                  <input
                    type="password"
                    className="form-control mb-3"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                  <label>Teléfono (WhatsApp)</label>
                  <input
                    type="tel"
                    className="form-control mb-3"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    value="Entrar"
                  >
                    {spinner ? (
                      <div className="spinner-border"></div>
                    ) : (
                      "Regístrate"
                    )}
                  </button>
                </form>
                <div className="container-fluid px-0 mt-4">
                  ¿Ya tienes cuenta?{" "}
                  <Link className="text-accent" to={"/entrar"}>
                    Inicia Sesión
                  </Link>
                </div>
              </div>
              <p className="mt-4 text-white mw-500 d-block m-auto">
                ¿Necesitas recuperar tu cuenta?{" "}
                <Link className="text-accent" to={"/recuperar"}>
                  Haz click aquí
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
