import React, { useContext, useEffect } from "react";
import { ClassTypeContext } from "../context/ClassTypesContext";
import FilterSection from "../components/videos/FilterSection";
import { FiltersContext } from "../context/FiltersContext";
import { VideosContext } from "../context/VideosContext";
import VideoCard from "../components/videos/VideoCard";
import PanelHeader from "../components/panel/PanelHeader";
import { UserContext } from "../context/UserContext";

const VideoClassType = ({ class_type_id }) => {
  const { videos } = useContext(VideosContext);

  const { userLoggedIn } = useContext(UserContext);

  const { setFilter } = useContext(FiltersContext);

  const { class_type } = useContext(ClassTypeContext);

  useEffect(() => {
    userLoggedIn();
    setFilter("class_type_id", class_type_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_type_id]);

  const renderTitle = () => {
    if (class_type && class_type !== null) {
      return class_type.name;
    }
    return "Workouts";
  };

  const renderVideos = () => {
    if (Array.isArray(videos)) {
      if (videos.length === 0) {
        return <p>No hay videos disponibles para tu búsqueda.</p>;
      }
      return videos.map((video) => (
        <VideoCard key={video.video_id} video={video} />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container text-white px-0">
      <PanelHeader title={renderTitle()} />
      <FilterSection />
      <div className="container">
        <div className="row">{renderVideos()}</div>
      </div>
    </div>
  );
};

export default VideoClassType;
