import api from "./api";

const route = "/purchases";

const OrdenesService = {
  getOrdenes: () => api.get(route),
  getOrden: (payment_id) => api.get(`${route}/${payment_id}`),
  postRefund: (payment_id) => api.post(`${route}/refund`, { payment_id }),
};

export default OrdenesService;
