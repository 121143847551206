import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";
import StatusBadge from "../global/StatusBadge";
import { navigate } from "@reach/router";

const PurchaseCard = ({ purchase, cancelSubscription }) => {
  const handleCancel = (purchase) => {
    if (purchase.order_id === "apple") {
      return navigate("/cancel/apple");
    }
    cancelSubscription(purchase);
  };

  return (
    <tr className="bg-gray text-white border">
      <td>#{purchase.purchase_id}</td>
      <td>{purchase.class_package.title}</td>
      <td>
        {"$"}
        {formatMonto(purchase.amount)}
        {" MXN"}
      </td>
      <td>{moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>{purchase.expiration_days}</td>
      <td>
        <StatusBadge status={purchase.status} order_id={purchase.order_id} />
      </td>
      <td>
        {moment(purchase.createdAt)
          .add(purchase.expiration_days, "days")
          .format("DD MMM YYYY")}
      </td>
      <td>
        {moment(purchase.createdAt)
          .add(purchase.expiration_days, "days")
          .format("DD MMM YYYY HH:mm")}
      </td>
      <td>
        {purchase.status === "active" && (
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handleCancel(purchase)}
          >
            <i className="fa fa-trash"></i>
          </button>
        )}
      </td>
    </tr>
  );
};

export default PurchaseCard;
