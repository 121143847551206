import React from "react";
import { S3_ENDPOINT } from "../../utils";

const banners = [
  {
    title: "Empieza hoy. Sin excusas.",
    src: "banner-1.jpg",
  },
  {
    title: "Entrena como quieras. Y dónde quieras.",
    src: "banner-2.jpg",
  },
  {
    title: "Nunca había sido tan fácil dar tu primer paso.",
    src: "banner-3.jpg",
  },
];

const SingleBanner = ({ title, src }) => {
  return (
    <div className="carousel-item active">
      <img
        src={`${S3_ENDPOINT}/${src}`}
        className="carousel-img d-block w-100"
        alt={title}
      />
      <div
        className="carousel-caption text-shadow"
        style={{
          bottom: `calc(50% - ${(String(title).length / 22) * 4.5}rem)`,
        }}
      >
        <h5 className="display-2">{title}</h5>
      </div>
    </div>
  );
};

const BannerCarousel = () => {
  const renderBanners = () => {
    if (Array.isArray(banners)) {
      return banners.map((banner, index) => (
        <SingleBanner key={index} {...banner} />
      ));
    }
  };

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="true"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">{renderBanners()}</div>
    </div>
  );
};

export default BannerCarousel;
