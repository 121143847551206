import React, { useContext, useEffect, useState } from "react";
import { FiltersContext } from "../../context/FiltersContext";
import { VideosContext } from "../../context/VideosContext";
import { UserContext } from "../../context/UserContext";
import FilterForm from "./FilterForm";

const FilterSection = ({ page }) => {
  const [showFilters, setShowFilters] = useState(false);
  const { user } = useContext(UserContext);
  const {
    class_category_id,
    instructor_id,
    class_type_id,
    video_type_id,
    showFavorites,
    setFilter,
    query,
  } = useContext(FiltersContext);

  const {
    spinner,
    getVideos,
    clearReload,
    reloadVideos,
    getFavoritos,
    clearFavoritos,
  } = useContext(VideosContext);

  useEffect(() => {
    if (!spinner && class_type_id !== null) {
      fetchVideos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    class_category_id,
    instructor_id,
    class_type_id,
    video_type_id,
    reloadVideos,
    query,
    user,
  ]);

  const fetchVideos = () => {
    const params = {
      instructor_id,
      video_type_id,
      class_type_id,
      query,
      page,
    };
    if (showFavorites) {
      getFavoritos(params);
    } else {
      clearFavoritos();
      getVideos(params);
    }
    clearReload();
  };

  const renderFilters = () => {
    if (showFilters) {
      return (
        <div className="card border bg-gray p-3 mb-3">
          <FilterForm />
        </div>
      );
    }
  };

  return (
    <div>
      <div className="row mb-3 mx-0 align-items-center">
        <div className="col-10 px-0">
          <input
            type="text"
            className="form-control"
            value={query}
            placeholder="Buscar un workout..."
            onChange={(e) => setFilter("query", e.target.value)}
          />
        </div>
        <div className="col-2 pe-0 text-center">
          <button
            className={`btn btn-sm w-100 text-white border p-2 ${
              showFilters ? "bg-danger" : "bg-black"
            }`}
            onClick={() => setShowFilters(!showFilters)}
          >
            <i className="fas fa-sliders-h me-1"></i>
          </button>
        </div>
      </div>
      {renderFilters()}
    </div>
  );
};

export default FilterSection;
