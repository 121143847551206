import React, { createContext, useContext, useReducer } from "react";
import AuthService from "../services/AuthService";
import UsuarioService from "../services/UsuarioService";
import AdjuntosService from "../services/AdjuntosService";
import UserReducer from "../reducers/UserReducer";
import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  LOGIN,
  LOGOUT,
  SET_PROPIEDAD_USER,
  GUARDAR_USUARIO,
  EDITAR_USUARIO,
} from "../types";
import { ModalContext } from "./ModalContext";
import CustomerService from "../services/CustomerService";
import { navigate } from "@reach/router";
import moment from "moment";
import { hideModal } from "../utils";

const initialState = {
  user: null,
  correo: null,
  password: null,
  telefono: null,
  cuenta: null,
  direccion: null,
  spinner: false,
};

export const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  function signIn(email, password) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signIn(email, password)
      .then((user) => {
        if (user) {
          UsuarioService.getUsuario()
            .then((res) => {
              let { customer } = res.data;
              dispatch({
                type: LOGIN,
                payload: customer,
              });
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status !== 400) {
                  AuthService.signOut();
                  return alert(error.toString());
                }
              }
              alert(error);
              AuthService.signOut();
            });
        } else {
          alert(
            "Ya tienes cuenta en MUSCLE FACTORY pero la contraseña es incorrecta. Por favor, intenta de nuevo"
          );
          dispatch({ type: HIDE_SPINNER });
          AuthService.signOut();
        }
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert(
            "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
          );
        }
        if (error.code === "auth/wrong-password") {
          alert("La contraseña es incorrecta. Por favor, intenta de nuevo");
        } else {
          alert(error);
        }
        dispatch({ type: HIDE_SPINNER });
      });
  }

  function userLoggedIn() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.userLoggedIn(
      () => {
        UsuarioService.getUsuario()
          .then((res) => {
            let { customer } = res.data;
            dispatch({
              type: LOGIN,
              payload: customer,
            });
            dispatch({ type: HIDE_SPINNER });
          })
          .catch((error) => {
            alert(error);
            dispatch({ type: HIDE_SPINNER });
          });
      },
      (error) => {
        if (error) {
          alert(error);
          AuthService.signOut();
        }
        dispatch({ type: HIDE_SPINNER });
      }
    );
  }

  function signOut() {
    AuthService.signOut()
      .then(() => {
        dispatch({ type: LOGOUT });
        navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function signUp(nombre, correo, password, telefono) {
    dispatch({ type: SHOW_SPINNER });
    if (!nombre || nombre === "") return alert("Ingresa tu nombre");
    if (!correo || correo === "") return alert("Ingresa tu correo");
    if (!telefono || telefono === "") return alert("Ingresa tu telefono");
    AuthService.signUp(correo, password)
      .then((user) => {
        const uid = user.user.uid;
        CustomerService.postSignUp({
          name: nombre,
          email: correo,
          phone: telefono,
          uid,
        }).then(() => {
          signIn(correo, password);
        });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          if (error.response.status === 412) {
            return alert(
              "Ya tienes una cuenta en MUSCLE FATORY. La contraseña que ingresaste es incorrecta."
            );
          }
        }
        if (error.message === "EMAIL_EXISTS") {
          return alert(
            "Ya existe una cuenta con ese correo electrónico, inicia sesión."
          );
        }
        alert(error.toString());
      });
  }

  function getUsuario() {
    UsuarioService.getUsuario().then((res) => {
      const { customer } = res.data;
      dispatch({ type: LOGIN, payload: customer });
    });
  }

  function editarUsuario() {
    dispatch({ type: EDITAR_USUARIO });
  }

  function cancelEdit() {
    dispatch({ type: GUARDAR_USUARIO });
  }

  function setPropiedadUser(key, value) {
    if (key === "idAdjunto") {
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key: "file", value } });
      if (!value) {
        dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
      }
    } else {
      if (key === "telefono") {
        value = String(value).replace(/\D/g, "");
        value = String(value).substring(0, 10);
      }
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    }
  }

  function recoverPassword(email) {
    AuthService.recoverPassword(email)
      .then(() => {
        success("Te hemos enviado un correo para reestablecer tu contraseña.");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          CustomerService.recoverPassword(email).then(() => {
            success(
              "¡Te hemos enviado un correo para reestablecer tu contraseña!"
            );
          });
        } else {
          alert("Hubo un error al enviar el correo. Inténtalo más tarde.");
        }
      });
  }

  function updateUsuario(usuario) {
    const promises = [];
    let data = { ...usuario };
    delete data.purchases;
    delete data.workouts;
    if (data.picture && data.picture !== null) {
      if (data.picture.name) {
        const promiseAdjunto = new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", data.picture);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { file_id } = res.data;
            data.file_id = file_id;
            resolve();
          });
        });
        promises.push(promiseAdjunto);
      }
    }
    Promise.all(promises).then(() => {
      delete data.file;
      delete data.uid;
      delete data.activo;
      data.birthday = moment(data.birthday).format("YYYY-MM-DD");
      UsuarioService.putUsuario(data)
        .then(() => {
          getUsuario();
          navigate("/myfactory/account");
          dispatch({ type: GUARDAR_USUARIO });
          success("Perfil actualizado con éxito.");
        })
        .catch((error) => {
          alert(error);
        });
    });
  }

  function deleteCustomer() {
    dispatch({ type: SHOW_SPINNER });
    CustomerService.deleteCustomer()
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Cuenta eliminada.");
        AuthService.signOut();
        navigate("/");
        hideModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        getUsuario,
        cancelEdit,
        userLoggedIn,
        updateUsuario,
        editarUsuario,
        deleteCustomer,
        recoverPassword,
        setPropiedadUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
