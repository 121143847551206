import React from "react";
import { useContext } from "react";
import Navbar from "../components/global/Navbar";
import { CapacitorContext } from "../context/CapacitorContext";
import AppLanding from "./AppLanding";
import Landing from "./Landing";

const Home = () => {
  const { platform } = useContext(CapacitorContext);
  return (
    <div>
      {platform !== "web" ? (
        <AppLanding />
      ) : (
        <>
          <Navbar />
          <Landing />
        </>
      )}
    </div>
  );
};

export default Home;
