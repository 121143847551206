import React from "react";
import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";

const VideoCard = ({ video }) => {
  const getSrc = () => {
    let src = "app-icon.png";
    if (video.file && video.file !== null) {
      src = `${video.file.name}.${video.file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  const isFavorite = () => {
    if (Array.isArray(video.favorites)) {
      return video.favorites.length > 0;
    }
  };

  const renderTitle = () => {
    if (String(video.name).length > 45) {
      return String(video.name).substring(0, 45) + "...";
    }
    return video.name;
  };

  const renderInstructors = () => {
    if (Array.isArray(video.video_instructors)) {
      if (video.video_instructors.length === 1) {
        const instructor = video.video_instructors[0].instructor;
        if (instructor !== null) {
          return (
            <span className="badge badge-pill small bg-primary text-white me-1">
              {instructor.nick_name !== null && instructor.nick_name !== ""
                ? instructor.nick_name
                : instructor.name}
            </span>
          );
        }
      } else {
        return video.video_instructors.map(({ instructor }) => (
          <span
            className="badge badge-pill bg-primary small text-white me-1"
            key={instructor.instructor_id}
          >
            {instructor.nick_name !== null && instructor.nick_name !== ""
              ? instructor.nick_name
              : instructor.name}
          </span>
        ));
      }
    }
  };

  return (
    <div className="col-6 col-md-6 col-lg-4 col-xl-3 px-1">
      <Link
        to={`/myfactory/video/${video.video_id}`}
        className="text-dark no-decoration"
        style={{ zIndex: 0 }}
      >
        <div className="card video-card border px-0 py-0 text-white no-decoration mb-3 positio-relative">
          <img src={getSrc()} alt="video thumbnail" />
          <div className="video-card-caption flex-column p-2">
            <div className="d-block w-100">
              <p className="bold mb-0 text-white small">{renderTitle()}</p>

              <button className="btn px-0 py-0 me-2 d-inline-block">
                <i
                  className={`fa fa-heart small ${
                    isFavorite() ? "text-pink" : "text-white"
                  }`}
                ></i>
              </button>
              {renderInstructors()}
            </div>
          </div>
          <div className="class-type-overlay"></div>
        </div>
      </Link>
    </div>
  );
};

export default VideoCard;
