import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";
import StatusBadge from "../global/StatusBadge";

const InvoiceCard = ({ invoice }) => {
  return (
    <tr className="bg-gray text-white border">
      <td>#{invoice.invoice_id}</td>
      <td>#{invoice.purchase_id}</td>
      <td>
        {"$"}
        {formatMonto(invoice.amount)}
        {" MXN"}
      </td>
      <td>{moment(invoice.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>
        <StatusBadge status={invoice.status} order_id={invoice.order_id} />
      </td>
    </tr>
  );
};

export default InvoiceCard;
