import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import UserForm from "../components/user/UserForm";
import { navigate } from "@reach/router";

const EditInformacion = () => {
  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(UserContext);

  useEffect(() => {
    getUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUsuario(user);
  };

  const handleCancel = () => {
    navigate("/myfactory/account");
  };

  const renderInformacion = () => {
    if (user !== null) {
      return (
        <UserForm
          user={user}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          setPropiedadUser={setPropiedadUser}
        />
      );
    }
  };

  return (
    <div className="container px-0">
      <h1>Editar Información</h1>
      <div className="card border bg-gray p-4 shadow-sm no-scale">
        {renderInformacion()}
      </div>
    </div>
  );
};

export default EditInformacion;
