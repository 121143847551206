import {
  ESPECIALES_HOME_RECIBIDOS,
  ONLINE_HOME_RECIBIDOS,
  PRESENCIALES_HOME_RECIBIDOS,
} from "../types";

const HomeReducer = (state, { type, payload }) => {
  switch (type) {
    case ONLINE_HOME_RECIBIDOS:
      return { ...state, online: payload };
    case PRESENCIALES_HOME_RECIBIDOS:
      return { ...state, presenciales: payload };
    case ESPECIALES_HOME_RECIBIDOS:
      return { ...state, especiales: payload };
    default:
      return { ...state };
  }
};

export default HomeReducer;
