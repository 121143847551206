import { Link } from "@reach/router";
import React, { useContext } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";

const CheckoutDisclaimer = () => {
  const { class_package } = useContext(CheckoutContext);

  const renderDisclaimer = () => {
    if (class_package && class_package !== null) {
      if (class_package.is_subscription) {
        return (
          <div>
            <h6>Recuerda</h6>
            <p className="small">
              * Tu suscripción se renovará automáticamente hasta que la
              canceles. Puedes cancelar en cualquier momento. Los cobros por las
              suscripciones no son reembolsables. Al realizar tu compra nos
              autorizas a cobrar a través de tu tarjeta de crédito o débito,
              aceptas nuestros Términos de servicio, Políticas de cookies y de
              privacidad, además declaras tener al menos 16 años de edad.
            </p>
          </div>
        );
      }
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="card">
        <p className="small">
          Al comprar cualquier servicio de MUSCLE FACTORY by Vogel aceptas
          nuestros{" "}
          <Link to="/terminos-y-condiciones" className="text-white small">
            Términos y Condiciones
          </Link>{" "}
          y nuestro{" "}
          <Link to="/privacidad" className="text-white small">
            Aviso de Privacidad
          </Link>
        </p>
        {renderDisclaimer()}
      </div>
    </div>
  );
};

export default CheckoutDisclaimer;
