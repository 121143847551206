import React, { useContext, useEffect } from "react";
import PanelHeader from "../components/panel/PanelHeader";
import { VideosContext } from "../context/VideosContext";

const SingleVideo = ({ video_id }) => {
  const { video, getSingleVideo, agregarFavorito, eliminarFavorito } =
    useContext(VideosContext);

  useEffect(() => {
    getSingleVideo(video_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderVideo = () => {
    if (video && video !== null) {
      return (
        <div>
          <iframe
            className="video-iframe"
            title="Workout"
            src={`https://player.vimeo.com/video/${video.url}`}
            allowFullScreen
          ></iframe>
        </div>
      );
    }
  };

  const isFavorito = () => {
    if (video && video !== null) {
      return video.favorites.length > 0;
    }
    return false;
  };

  const renderDescription = () => {
    if (video && video !== null) {
      return (
        <div className="px-3">
          <div className="row border-bottom pb-2 mb-2 align-items-center">
            <div className="col-10 px-0">
              <h3 className="h5 small mb-0">{video.name}</h3>
            </div>
            <div className="col-2 px-0 text-end">
              <button
                className={`btn btn-sm px-0 py-0 ${
                  isFavorito() ? "text-primary" : "text-white"
                }`}
                onClick={() => {
                  if (!isFavorito()) {
                    agregarFavorito(video.video_id);
                  } else {
                    eliminarFavorito(video.video_id);
                  }
                }}
              >
                <i className="fas fa-heart"></i>
              </button>
            </div>
          </div>
          <p>{video.description}</p>
        </div>
      );
    }
  };

  return (
    <div className="container px-0">
      <PanelHeader title="Mi Clase" />
      {renderVideo()}
      {renderDescription()}
    </div>
  );
};

export default SingleVideo;
