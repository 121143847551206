import { Link } from "@reach/router";
import React from "react";
import { useContext } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import { S3_ENDPOINT, hasNotch } from "../../utils";

const PanelHeader = ({ title }) => {
  const { device } = useContext(CapacitorContext);
  return (
    <div
      className={`row align-items-center mx-0 border-bottom pb-2 mb-3 ${
        hasNotch(device) ? "pt-4" : "pt-2"
      }`}
    >
      <div className="col-2 ps-0">
        {window.location.pathname !== "/" &&
        window.location.pathname !== "/myfactory" ? (
          <button
            onClick={() => window.history.back()}
            className="btn btn-sm px-3 text-white d-inline border"
          >
            <i className="fa fa-chevron-left"></i>
          </button>
        ) : (
          <Link
            to={window.location.pathname === "/myfactory" ? "/" : "/myfactory"}
            className="d-inline"
          >
            <img
              alt="MUSCLE FACTORY Logo"
              src={`${S3_ENDPOINT}/icono-blanco.png`}
              className="panel-header-logo w-100"
            />
          </Link>
        )}
      </div>
      <div className="col-8 text-center">
        {window.location.pathname !== "/" &&
        window.location.pathname !== "/myfactory" ? (
          <div>
            <Link
              to={
                window.location.pathname === "/myfactory" ? "/" : "/myfactory"
              }
              className="d-inline"
            >
              <img
                alt="MUSCLE FACTORY Logo"
                src={`${S3_ENDPOINT}/icono-blanco.png`}
                className="panel-header-logo w-100 hide-mobile"
              />
              <h1 className="h2 d-inline-block ms-2 text-white">{title}</h1>
            </Link>
          </div>
        ) : (
          <h1 className="h2 mb-0 d-inline-block mx-2">{title}</h1>
        )}
      </div>
      <div className="col-2 text-end">
        <Link to="/myfactory/account" className="text-white">
          <i className="fa fa-2x fa-user-circle"></i>
        </Link>
      </div>
    </div>
  );
};

export default PanelHeader;
