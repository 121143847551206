import React from "react";
import { S3_ENDPOINT } from "../../utils";

const FeatureList = () => {
  return (
    <div className="container-fluid p-5 text-white bg-dark">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <img
              alt="Daniel Vogel"
              src={`${S3_ENDPOINT}/foto-bio.jpg`}
              className="mw-100 w-100 m-auto d-block p-3"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="card bg-black px-4 border">
              <h2 className="h3">¿Por qué entrenar conmigo?</h2>
              <h3 className="h5">Una nota de Daniel Vogel</h3>
              <p>
                Te aseguro un entrenamiento de alto rendimiento, tal y como lo
                vive un atleta.
              </p>
              <p>
                Me apasiona impartir la sabiduría fitness a los demás y
                ayudarlos a convertirse en la mejor versión de sí mismos.
              </p>
              <p>
                En nuestra aplicación y sitio web, es fácil encontrar la clase
                exacta que se ajuste a su límite de tiempo y nivel de condición
                física actuales.
              </p>
              <a href="#pricing" className="btn btn-primary w-100">
                SUSCRÍBETE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureList;
