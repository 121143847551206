import React from "react";
import { S3_ENDPOINT } from "../../utils";

const About = () => {
  return (
    <div className="container-fluid p-5 bg-black text-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <h2 className="display-3">
              Acerca de <br />
              MUSCLE FACTORY
            </h2>
            <p>
              En MUSCLE FACTORY by Vogel, estamos motivados a ayudarte a
              convertirte en la mejor versión de tí posible.
            </p>
            <h3>Prepárate para</h3>
            <ul className="list-style-none">
              <li>
                <i className="fas fa-check-circle text-accent me-2"></i>Mejorar
                tu ritmo cardiaco.
              </li>
              <li>
                <i className="fas fa-check-circle text-accent me-2"></i>Aumentar
                tu masa muscular.
              </li>
              <li>
                <i className="fas fa-check-circle text-accent me-2"></i>Trabajar
                en tu flexibilidad.
              </li>
              <li>
                <i className="fas fa-check-circle text-accent me-2"></i>Mejorar
                tu resistencia.
              </li>
              <li>
                <i className="fas fa-check-circle text-accent me-2"></i>Un
                entrenamiento de alto rendimiento.
              </li>
              <li>
                <i className="fas fa-check-circle text-accent me-2"></i>Guiado
                por entrenadores especializados.
              </li>
            </ul>
            <a href="#pricing" className="btn btn-primary">
              SUSCRÍBETE
            </a>
          </div>
          <div className="col-12 col-lg-6">
            <img
              alt="Acerca de MUSCLE FACTORY"
              src={`${S3_ENDPOINT}/about-muscle-factory.png`}
              className="mw-100 w-100 d-block m-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
