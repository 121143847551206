import React from "react";
import FeatureItem from "./FeatureItem";

const features = [
  {
    title: "Salud es primero",
    description: "Nuestra mayor prioridad es tu bienestar físico.",
  },
  {
    title: "Donde sea, cuando sea",
    description: "Puedes hacer las clases sin importar tu horario.",
  },
  {
    title: "Con y sin material",
    description: "Logra tu máximo potencial sin importar el equipo.",
  },
  {
    title: "Gama de clases",
    description: "Desde fuerza hasta yoga, para que no pierdas la motivación.",
  },
];

const Features = () => {
  const renderFeatures = () => {
    if (Array.isArray(features)) {
      return features.map((feature, index) => (
        <FeatureItem key={index} {...feature} />
      ));
    }
  };

  return (
    <div className="container-fluid py-5 bg-dark">
      <div className="container pt-3">
        <div className="row align-items-center mb-3">
          <div className="col-12 col-lg-6">
            <h2 className="text-white display-6">Explora Nuestros</h2>
            <h2 className="text-white display-1">Workouts</h2>
          </div>
        </div>
      </div>
      <div className="feature-carousel-wrapper pb-5">
        <div
          className="feature-carousel"
          style={{ width: `${320 * features.length}px` }}
        >
          {renderFeatures()}
        </div>
      </div>
    </div>
  );
};

export default Features;
