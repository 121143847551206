import { Link } from "@reach/router";
import React from "react";
import Page from "../components/global/Page";

const CancelAppleSubscriptions = () => {
  return (
    <Page title="Cancelar tu Suscripción">
      <h2>Apple</h2>
      <p>
        Al cancelar tu suscipción perderás acceso al contenido al final de tu
        período de facturación. Tu información se mantendrá guardada para cuando
        decidas regresar a entrenar. Siempre puedes eliminar tu cuenta por
        completo en el menú de usuario.
      </p>
      <p>
        Para cancelar tu suscripción adquirida desde el app móvil en Apple,
        sigue estos pasos:
      </p>
      <ol>
        <li>Abre la App Store en tu dispositivo.</li>
        <li>
          Haz click en el ícono de usuario <i className="fa fa-user-circle"></i>
        </li>
        <li>
          Asegúrate que tienes la sesión iniciada en la misma cuenta con la que
          hiciste tu compra.
        </li>
        <li>Haz click en el botón "Suscripciones"</li>
        <li>Haz click en tu suscripción de MUSCLE FACTORY by Vogel</li>
        <lu>Haz click en "Cancelar Suscripción"</lu>
      </ol>
      <p>
        Listo. Tu suscripción habrá sido cancelada y no se te hará ningún cargo
        próximo.
      </p>
      <Link to="/" className="btn btn-primary mt-4">
        Regresar a Inicio
      </Link>
    </Page>
  );
};

export default CancelAppleSubscriptions;
