import React from "react";

const Hero = () => {
  return (
    <div id="hero" className="container-fluid bg-black p-5">
      <div className="container px-0 h-100">
        <div className="row hero-row h-100">
          <div className="col-12 col-lg-6 mobile-center">
            <h1 className="text-white display-1">
              Empieza hoy <br />
              Sin Excusas
            </h1>
            <h2 className="h3 text-white">
              No solo te vas a ver mejor, te vas a sentir mejor.
            </h2>
            <div className="row mt-4">
              <div className="container-fluid">
                <div className="row mb-5">
                  <div className="col-6">
                    <a href="#pricing" className="btn btn-primary w-100">
                      SUSCRÍBETE
                    </a>
                  </div>
                  <div className="col-6">
                    <a href="#trailer" className="btn btn-light w-100">
                      <i className="fas fa-play-circle"></i> TRAILER
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
