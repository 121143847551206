import React from "react";

const Trailer = () => {
  return (
    <div id="trailer" className="container-fluid bg-camo">
      <div className="container text-center text-white py-5">
        <h2 className="text-center display-3">Watch Trailer</h2>
        <video
          controls
          className="trailer d-block m-auto"
          poster="https://musclefactory.s3.us-west-1.amazonaws.com/trailer-cover.jpg"
        >
          <source src="https://musclefactory.s3.us-west-1.amazonaws.com/trailer.mp4" />
        </video>
      </div>
    </div>
  );
};

export default Trailer;
