import React, { useEffect } from "react";
import Footer from "../components/global/Footer";
import Hero from "../components/landing/Hero";
import Features from "../components/landing/Features";
import About from "../components/landing/About";
import FeatureList from "../components/landing/FeatureList";
import BannerCarousel from "../components/landing/BannerCarousel";
import Trailer from "../components/landing/Trailer";
import Pricing from "../components/landing/Pricing";

const Landing = () => {
  useEffect(() => {
    document.onscroll = handleScroll;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    let navbar = document.getElementById("navbar");
    if (window.scrollY > 100 && navbar !== null) {
      navbar.style.backgroundColor = "#000";
    } else if (navbar !== null) {
      navbar.style.backgroundColor = "transparent";
    }
  };

  return (
    <div>
      <Hero />
      <Trailer />
      <Features />
      <About />
      <FeatureList />
      <Pricing />
      <div className="container py-5">
        <BannerCarousel />
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
