import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../utils";
import React from "react";

const AppLanding = () => {
  return (
    <div id="app-landing">
      <div id="hero" className="container-fluid bg-black pt-0">
        <div className="row align-items-center mx-0 h-100">
          <img
            alt="Logo MUSCLE FACTORY"
            src={`${S3_ENDPOINT}/logo-blanco.png`}
            className="w-100 d-block m-auto"
          />
        </div>
      </div>
      <div className="app-landing bg-black pt-4 p-3">
        <div className="container text-center">
          <h1 className="text-white display-1">
            Empieza hoy <br />
            Sin Excusas
          </h1>
          <h2 className="h3 text-white mb-0">
            No solo te vas a ver mejor, <br />
            te vas a sentir mejor.
          </h2>
          <div className="container mt-5">
            <Link to="/myfactory" className="btn btn-primary w-100">
              Acceder
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLanding;
