import React, { useContext, useEffect } from "react";
import PanelHeader from "../components/panel/PanelHeader";
import ClassTypeCard from "../components/videos/ClassTypeCard";
import FilterSection from "../components/videos/FilterSection";
import { ClassTypeContext } from "../context/ClassTypesContext";

const Videos = () => {
  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getClassTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      return class_types.map((class_type) => (
        <div key={class_type.class_type_id} className="col-6 col-xl-4">
          <ClassTypeCard class_type={class_type} />
        </div>
      ));
    }
  };

  return (
    <div className="container text-white px-0">
      <PanelHeader title="Workouts" />
      <FilterSection />
      <div className="row">{renderClassTypes()}</div>
    </div>
  );
};

export default Videos;
