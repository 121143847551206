import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import UserForm from "../components/user/UserForm";
import UserData from "../components/user/UserData";
import { navigate } from "@reach/router";
import PanelHeader from "../components/panel/PanelHeader";

const MiInformacion = () => {
  const [editMode, setEditMode] = useState(false);

  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
    updateUsuario(user);
  };

  const handleCancel = () => {
    setEditMode(false);
    getUsuario();
  };

  const handleEdit = () => {
    navigate("/myfactory/account/edit");
  };

  const renderInformacion = () => {
    if (user && user !== null) {
      if (editMode) {
        return (
          <UserForm
            user={user}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            setPropiedadUser={setPropiedadUser}
          />
        );
      }
      return <UserData user={user} handleEdit={handleEdit} />;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container px-0">
      <PanelHeader title="Mi Perfil" />
      <div className="card bg-gray border p-3 shadow-sm no-scale">
        {renderInformacion()}
      </div>
    </div>
  );
};

export default MiInformacion;
