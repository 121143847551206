import React, { useContext, useEffect } from "react";
import { CapacitorContext } from "../context/CapacitorContext";
import { UserContext } from "../context/UserContext";
import VideoClassType from "./VideoClassType";
import SingleVideo from "./SingleVideo";
import { Router } from "@reach/router";
import { hasNotch } from "../utils";
import Account from "./Account";
import Videos from "./Videos";
import Shop from "./Shop";

const Panel = () => {
  const { getUsuario } = useContext(UserContext);

  const { device } = useContext(CapacitorContext);

  useEffect(() => {
    getUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notch = hasNotch(device);

  return (
    <div className="text-white">
      <div className="container-fluid bg-camo">
        <div
          className="content px-1 pe-2"
          style={{
            paddingTop: notch ? "2.5rem" : "1.5rem",
            paddingBottom: notch ? "2rem" : "3rem",
          }}
        >
          <Router>
            <Videos path="/" default />
            <VideoClassType path="/class_type/:class_type_id" />
            <SingleVideo path="/video/:video_id" />
            <Account path="/account/*" />
            <Shop path="/shop" />
          </Router>
        </div>
      </div>
    </div>
  );
};

export default Panel;
