import React, { useContext, useEffect } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import { FiltersContext } from "../../context/FiltersContext";
import { VideosContext } from "../../context/VideosContext";
import { VideoTypeContext } from "../../context/VideoTypeContext";

const FilterForm = () => {
  const { video_types, getVideoTypes } = useContext(VideoTypeContext);

  const { completed, setCompleted } = useContext(VideosContext);

  const { coaches, getCoaches } = useContext(CoachesContext);

  const { instructor_id, video_type_id, showFavorites, setFilter } =
    useContext(FiltersContext);

  useEffect(() => {
    getVideoTypes();
    getCoaches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCoaches = () => {
    if (Array.isArray(coaches)) {
      return [
        <option key="todos" value="">
          Todo
        </option>,
        ...coaches.map((coach) => (
          <option key={coach.instructor_id} value={coach.instructor_id}>
            {coach.name} {coach.last_name}
          </option>
        )),
      ];
    }
  };

  const renderVideoTypes = () => {
    if (Array.isArray(video_types)) {
      return [
        <option key="todos" value="">
          Todos
        </option>,
        ...video_types.map((video_type) => (
          <option
            key={video_type.video_type_id}
            value={video_type.video_type_id}
          >
            {video_type.name}
          </option>
        )),
      ];
    }
  };
  return (
    <div className="row text-white align-items-center">
      <div className="col-12 col-md-4 mb-2">
        <label className="mb-1">Target</label>
        <select
          value={video_type_id}
          className="form-control"
          onChange={(e) => setFilter("video_type_id", e.target.value)}
        >
          {renderVideoTypes()}
        </select>
      </div>
      <div className="col-12 col-md-4 mb-2">
        <label className="mb-1">Coach</label>
        <select
          value={instructor_id}
          className="form-control"
          onChange={(e) => setFilter("instructor_id", e.target.value)}
        >
          {renderCoaches()}
        </select>
      </div>
      <div className="col-12 col-md-4 pt-3">
        <button
          className={`btn br-10 btn-sm border me-2 ${
            showFavorites
              ? "text-primary border-primary"
              : "text-white border-white"
          }`}
          onClick={() => setFilter("showFavorites", !showFavorites)}
        >
          <i className="fa fa-heart me-1"></i> Favoritos
        </button>
        <button
          className={`btn br-10 btn-sm border me-2 ${
            completed
              ? "text-primary border-primary"
              : "text-white border-white"
          }`}
          onClick={() => setCompleted(!completed)}
        >
          <i className="fa fa-check me-1"></i> Completados
        </button>
      </div>
    </div>
  );
};

export default FilterForm;
