import { Link } from "@reach/router";
import React from "react";
import { S3_ENDPOINT } from "../../utils";

const ClassTypeCard = ({ class_type }) => {
  const getSrc = () => {
    let src = "app-icon.png";
    const { file } = class_type;
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  const renderVideos = () => {
    if (class_type && class_type !== null) {
      const { videos } = class_type;
      if (Array.isArray(videos)) {
        return (
          <span className="badge badge-pill bg-black br-10">
            {videos.length} videos
          </span>
        );
      }
    }
  };

  let { class_type_id, name } = class_type;

  return (
    <Link
      to={`/myfactory/class_type/${class_type_id}`}
      className="card class-type-card border px-0 py-0 text-white no-decoration mb-3"
    >
      <div className="card class-type-card border text-white px-0 py-0">
        <img alt="Tipo de Clase" src={getSrc()} className="class-type-img" />
        <div className="class-type-caption p-2">
          <h3 className="mb-1">{name}</h3>
          {renderVideos()}
        </div>
        <div className="class-type-overlay"></div>
      </div>
    </Link>
  );
};

export default ClassTypeCard;
