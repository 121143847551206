import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../context/CheckoutContext";
import { UserContext } from "../context/UserContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutClassPackage from "../components/checkout/CheckoutClassPackage";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";
import CheckoutDisclaimer from "../components/checkout/CheckoutDisclaimer";
import Footer from "../components/global/Footer";

const Checkout = ({ class_package_id }) => {
  const { user } = useContext(UserContext);

  //Checkout
  const {
    descuento,
    setPayPal,
    createOrder,
    setDescuento,
    discountCode,
    class_package,
    setPaymentSource,
    payment_source_id,
  } = useContext(CheckoutContext);

  useEffect(() => {
    return () => {
      setDescuento(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (descuento !== null && class_package !== null) {
      setPayPal(class_package_id, discountCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descuento]);

  useEffect(() => {
    const paypalButton = document.getElementById("paypal-button");
    if (user !== null && class_package !== null && paypalButton !== null) {
      if (paypalButton.innerHTML === "") {
        setPayPal(class_package_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, class_package]);

  const handleSubmit = () => {
    let discount =
      descuento && descuento !== null ? descuento.code : discountCode;
    createOrder(class_package_id, payment_source_id, discount);
  };

  return (
    <div id="checkout" className="container-fluid px-0 text-white">
      <CheckoutHeader />
      <div className="container checkout px-3">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-4 my-2">
            <CheckoutClassPackage class_package_id={class_package_id} />
            <CheckoutDiscount class_package_id={class_package_id} />
          </div>
          <div className="col-12 col-md-8 my-2">
            <CheckoutAccount />
            <CheckoutPaymentMethods
              paymentMethod={payment_source_id}
              class_package_id={class_package_id}
              setPaymentMethod={setPaymentSource}
              handleSubmitCheckout={handleSubmit}
            />
          </div>
          <div className="container-fluid">
            <CheckoutDisclaimer />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
