import React from "react";
import { combineComponents } from "./context";
import { CapacitorProvider } from "./context/CapacitorContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { CoachesProvider } from "./context/CoachesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { FiltersProvider } from "./context/FiltersContext";
import { HomeProvider } from "./context/HomeContext";
import { LocationsProvider } from "./context/LocationsContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ModalProvider } from "./context/ModalContext";
import { OrdenesProvider } from "./context/OrdenesContext";
import { PaquetesProvider } from "./context/PackageContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { ReservationsProvider } from "./context/ReservationsContext";
import { UserProvider } from "./context/UserContext";
import { VideosProvider } from "./context/VideosContext";
import { VideoTypeProvider } from "./context/VideoTypeContext";
import Main from "./Main";

const AppContextProvider = combineComponents([
  UserProvider,
  HomeProvider,
  VideosProvider,
  FiltersProvider,
  CoachesProvider,
  MetodosProvider,
  OrdenesProvider,
  CustomerProvider,
  PaquetesProvider,
  PurchaseProvider,
  CheckoutProvider,
  ClassTypeProvider,
  DiscountsProvider,
  LocationsProvider,
  VideoTypeProvider,
  CapacitorProvider,
  ReservationsProvider,
  ClassCategoryProvider,
]);

function App() {
  return (
    <ModalProvider>
      <AppContextProvider>
        <Main />
      </AppContextProvider>
    </ModalProvider>
  );
}

export default App;
