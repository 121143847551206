import api from "./api";

const route = "/purchases";

const PurchasesService = {
  getPurchases: () => api.get(route),
  getPurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  postApplePurchase: (purchase) =>
    api.post(`${route}/appstore`, { ...purchase }),
  postPlayStorePurchase: (purchase) =>
    api.post(`${route}/playstore`, { ...purchase }),
  cancelSubscription: (purchase_id, reason) =>
    api.post(`${route}/cancel/${purchase_id}`, {
      reason,
      status: "cancelled",
    }),
};

export default PurchasesService;
