import React, { useState } from "react";

const LoginForm = ({ handleSubmitLogin, setLogin, spinner }) => {
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitLogin(correo, password);
  };

  return (
    <div className="container-fluid px-0 mt-3 mb-4">
      <h3>Inicia Sesión</h3>
      <form className="card p-3 no-scale" onSubmit={handleSubmit}>
        <label>Correo</label>
        <input
          type="email"
          className="form-control mb-3"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
        />
        <label>Contraseña</label>
        <input
          type="password"
          className="form-control mb-3"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="row mb-2 align-items-center">
          <div className="col-12 col-md-6">
            <button type="submit" className="btn btn-primary w-100">
              {spinner ? <div className="spinner-border"></div> : "Entrar"}
            </button>
          </div>
          <div className="col-12 col-md-6 text-center">
            <p className="mt-3 small mb-0">
              ¿Aún no tienes cuenta?{" "}
              <button
                type="button"
                className="btn btn-sm btn-link d-inline px-0 py-0 text-white"
                onClick={() => setLogin(false)}
              >
                <span>Regístrate</span>
              </button>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
