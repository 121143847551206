import React from "react";

const FeatureItem = ({ title, description }) => {
  return (
    <div className="feature-carousel-item card bg-gray text-white p-5">
      <i className="fas fa-2x fa-dumbbell text-accent mb-3"></i>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default FeatureItem;
