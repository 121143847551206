import { Link } from "@reach/router";
import React, { useContext } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import { S3_ENDPOINT, hasNotch } from "../../utils";

const CheckoutHeader = () => {
  const { device } = useContext(CapacitorContext);

  return (
    <div
      className={`row border-bottom align-items-center pb-3 bg-dark ${
        hasNotch(device) ? "pt-5" : "pt-3"
      }`}
    >
      <div className="container-fluid">
        <div className="container pe-4">
          <div className="row align-items-center">
            <div className="col-8 col-md-10">
              <h1 className="text-white display-4 mb-0">Checkout</h1>
            </div>
            <div className="col-4 col-md-2 text-end">
              <Link to="/">
                <img
                  alt="MUSCLE FACTORY Logo"
                  src={`${S3_ENDPOINT}/icono-blanco.png`}
                  className="logo-navbar"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
