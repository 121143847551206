export const getBilling = (class_package) => {
  const { subscription_period, subscription_interval } = class_package;
  let period = subscription_period;
  let interval = subscription_interval;
  if (subscription_interval > 1) {
    period =
      subscription_period === "month"
        ? "meses"
        : subscription_period === "year"
        ? "años"
        : "días";
  } else {
    period =
      subscription_period === "month"
        ? "mes"
        : subscription_period === "year"
        ? "año"
        : "día";
  }
  let frequency = "cada ";
  if (interval === 1) {
    interval = "";
  }
  return `${frequency} ${interval} ${period}`;
};
