import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import { MetodosContext } from "../../context/MetodosContext";
import { UserContext } from "../../context/UserContext";
import PaymentMethodCard from "../paymentMethods/PaymentMethodCard";
import PaymentSource from "../metodos/PaymentSource";
import StripeCheckout from "../common/StripeCheckout";

const CheckoutPaymentMethods = ({
  paymentMethod,
  class_package_id,
  setPaymentMethod,
  handleSubmitCheckout,
}) => {
  const { spinner, discountCode } = useContext(CheckoutContext);
  const { user } = useContext(UserContext);

  const { metodos, getMetodos } = useContext(MetodosContext);

  useEffect(() => {
    if (user !== null) {
      getMetodos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (paymentMethod === "card") {
      let cardNumberInput = document.getElementById("card-number");
      if (cardNumberInput && cardNumberInput !== null) {
        cardNumberInput.placeholder = "Número de Tarjeta";
      }
    }
  }, [paymentMethod]);

  const renderPaymentSources = () => {
    if (metodos && metodos !== null) {
      return metodos.map((metodo) => (
        <PaymentSource
          key={metodo.payment_source_id}
          payment_source={metodo}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
        />
      ));
    }
  };

  const renderPago = () => {
    if (user !== null) {
      return (
        <div className="oveflow-hidden">
          <h3>Forma de Pago</h3>
          {renderPaymentSources()}
          <PaymentMethodCard
            name="card"
            label="Tarjeta de Crédito o Débito"
            selected={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          >
            {paymentMethod === "card" && (
              <StripeCheckout
                element_id={class_package_id}
                discountCode={discountCode}
              />
            )}
          </PaymentMethodCard>
          <div className="container-fluid px-0 text-right">
            {!["card", "paypal", "transfer"].includes(paymentMethod) && (
              <button
                disabled={spinner}
                onClick={handleSubmitCheckout}
                className="btn btn-primary btn-lg"
              >
                {spinner ? (
                  <div className="spinner-border"></div>
                ) : (
                  "Pagar Ahora"
                )}
              </button>
            )}
          </div>
        </div>
      );
    }
  };

  return <div>{renderPago()}</div>;
};

export default CheckoutPaymentMethods;
