import React, { useState, useContext, useEffect } from "react";
import PricingCard from "../components/paquetes/PricingCard";
import { PackagesContext } from "../context/PackageContext";
import PanelHeader from "../components/panel/PanelHeader";
import { UserContext } from "../context/UserContext";
import { CapacitorContext } from "../context/CapacitorContext";
import { Glassfy } from "capacitor-plugin-glassfy";

const Shop = () => {
  const [offerings, setOfferings] = useState(null);
  const { paquetes, getPaquetes } = useContext(PackagesContext);
  const { platform } = useContext(CapacitorContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getPaquetes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (platform === "ios" && Array.isArray(paquetes)) {
      Glassfy.initialize({
        apiKey: "54d5bd8cbbf844d19ea65a8e2db52029",
        watcherMode: false,
      })
        .then(() => {
          Glassfy.offerings()
            .then((current) => {
              setOfferings(current.all);
            })
            .catch((error) => {
              alert(error);
            });
        })
        .catch((error) => {
          alert(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paquetes]);

  const getCurrentPlan = () => {
    if (user && user !== null) {
      if (Array.isArray(user.purchases)) {
        let current = user.purchases.find(
          (purchase) => purchase.status === "active"
        );
        return current;
      }
    }
  };

  const renderPaqueteOnline = () => {
    const current = getCurrentPlan();
    if (Array.isArray(paquetes)) {
      let currentPaquetes = [...paquetes];
      if (currentPaquetes.length === 0) {
        return <p>No hay paquetes disponibles.</p>;
      }
      if (platform === "ios") {
        if (Array.isArray(offerings)) {
          currentPaquetes = currentPaquetes.map((paquete) => {
            const offering = offerings.find(
              (offering) => offering.offeringId === paquete.apple_product_id
            );
            if (offering) {
              const sku = offering.skus[0];
              return { ...paquete, sku };
            }
            return paquete;
          });
        }
      }
      return currentPaquetes.map((paquete) => (
        <div
          key={paquete.class_package_id}
          className="col-12 col-md-6 col-xl-3 text-white text-center"
        >
          <PricingCard class_package={paquete} current={current} />
        </div>
      ));
    }
    return <div className="spinner-border mx-2" role="status"></div>;
  };

  return (
    <div className="container px-0 pb-4 text-white">
      <PanelHeader title="Membresías" />
      <div className="row">{renderPaqueteOnline()}</div>
    </div>
  );
};

export default Shop;
