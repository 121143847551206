import React, { createContext, useContext, useReducer } from "react";
import OrdenesReducer from "../reducers/OrdenesReducer";
import InvoicesService from "../services/InvoicesService";
import PurchasesService from "../services/PurchasesService";
import {
  INVOICES_RECIBIDOS,
  ORDENES_RECIBIDAS,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_ORDEN,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";

const initialState = {
  purchases: null,
  purchase: null,
  invoices: null,
};

export const PurchasesContext = createContext(initialState);

export const PurchaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdenesReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const getPurchases = () => {
    PurchasesService.getPurchases().then((res) => {
      const { purchases } = res.data;
      dispatch({ type: ORDENES_RECIBIDAS, payload: purchases });
    });
  };

  const getInvoices = () => {
    InvoicesService.getInvoices().then((res) => {
      const { invoices } = res.data;
      dispatch({ type: INVOICES_RECIBIDOS, payload: invoices });
    });
  };

  const getPurchase = (purchase_id) => {
    PurchasesService.getPurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_ORDEN, payload: purchase });
    });
  };

  const cancelSubscription = (purchase_id, reason) => {
    dispatch({ type: SHOW_SPINNER });
    PurchasesService.cancelSubscription(purchase_id, reason)
      .then((res) => {
        success("¡Suscripción cancelada!");
        dispatch({ type: HIDE_SPINNER });
        getPurchases();
        hideModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const createPurchaseAppStore = (class_package_id) => {
    PurchasesService.postApplePurchase({
      class_package_id,
    })
      .then((res) => {
        success("Compra exitosa.");
        const { purchase } = res.data;
        const { purchase_id } = purchase;
        navigate(`/thankyou/${purchase_id}`);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const createPurchasePlayStore = (purchase) => {
    PurchasesService.postPlayStorePurchase(purchase)
      .then((res) => {
        success("Compra exitosa.");
        const { purchase } = res.data;
        const { purchase_id } = purchase;
        navigate(`/thankyou/${purchase_id}`);
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        getPurchase,
        getInvoices,
        getPurchases,
        cancelSubscription,
        createPurchaseAppStore,
        createPurchasePlayStore,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
