import { Link } from "@reach/router";
import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { S3_ENDPOINT } from "../utils";
import { navigate } from "@reach/router";

const Recuperar = () => {
  const [email, setEmail] = useState("");
  const { spinner, recoverPassword } = useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    recoverPassword(email);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 bg-black vh-100 px-0 hide-mobile">
          <div className="bg-vertical"></div>
        </div>
        <div className="col-12 col-md-6 bg-camo vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <div className="login-card p-3 text-center">
                <img
                  src={`${S3_ENDPOINT}/logo-blanco.png`}
                  className="w-100 mb-4 cursor-pointer"
                  alt="MUSCLE FACTORY Logo"
                  onClick={() => navigate("/")}
                />
                <h1 className="text-white my-4">Reestablece tu Contraseña</h1>
              </div>
              <div className="card login-card border bg-gray text-white no-scale text-left shadow p-4">
                <form onSubmit={handleSubmit}>
                  <label>Correo Electrónico</label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    value="Entrar"
                  >
                    {spinner ? (
                      <div className="spinner-border"></div>
                    ) : (
                      "Recuperar"
                    )}
                  </button>
                </form>
                <div className="container-fluid px-0 mt-4">
                  ¿Recordaste tu contraseña?{" "}
                  <Link to="/entrar" className="text-accent">
                    Inicia Sesión
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recuperar;
