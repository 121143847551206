import React, { useContext, useEffect } from "react";
import Home from "./views/Home";
import { Router } from "@reach/router";
import Login from "./views/Login";
import { UserContext } from "./context/UserContext";
import Modal from "./components/global/Modal";
import ErrorAlert from "./components/common/ErrorAlert";
import SuccessAlert from "./components/common/SuccessAlert";
import Checkout from "./views/Checkout";
import ThankYou from "./views/ThankYou";
import SignUp from "./views/SignUp";
import Recuperar from "./views/Recuperar";
import Panel from "./views/Panel";
import "./css/landing.css";
import "./css/colors.css";
import "./css/fonts.css";
import { CapacitorContext } from "./context/CapacitorContext";
import Terms from "./views/Terms";
import Privacy from "./views/Privacy";
import CancelAppleSubscriptions from "./views/CancelAppleSubscriptions";

const Main = () => {
  const { userLoggedIn } = useContext(UserContext);
  const { setupDevice, setupPlatform } = useContext(CapacitorContext);

  useEffect(() => {
    setupDevice();
    userLoggedIn();
    setupPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Router>
        <Home path="/*" default />
        <Login path="/entrar" />
        <SignUp path="/registro" />
        <Recuperar path="/recuperar" />
        <Checkout path="/checkout/:class_package_id/*" />
        <ThankYou path="/thankyou/:purchase_id/*" />
        <Panel path="/myfactory/*" />
        <Terms path="/terminos-y-condiciones" />
        <Privacy path="/privacidad" />
        <CancelAppleSubscriptions path="/cancel/apple" />
      </Router>
      <Modal />
      <ErrorAlert />
      <SuccessAlert />
    </>
  );
};

export default Main;
