import React, { useContext, useEffect } from "react";
import { PackagesContext } from "../../context/PackageContext";
import PricingCard from "../paquetes/PricingCard";

const Pricing = () => {
  const { paquetes, getPaquetes } = useContext(PackagesContext);

  useEffect(() => {
    getPaquetes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPaquetes = () => {
    if (Array.isArray(paquetes)) {
      return paquetes.map((paquete) => (
        <div
          key={paquete.class_package_id}
          className="col-12 col-md-6 col-xl-3 text-white text-center"
        >
          <PricingCard class_package={paquete} />
        </div>
      ));
    }
  };

  return (
    <div id="pricing" className="container-fluid py-5">
      <div className="container py-5 text-center text-white">
        <h2 className="display-3">Elige tu Plan</h2>
        <h3>Precio Único por Lanzamiento</h3>
        <div className="row">{renderPaquetes()}</div>
      </div>
    </div>
  );
};

export default Pricing;
