import React, { useContext, useEffect } from "react";
import { Link } from "@reach/router";
import { hasNotch, S3_ENDPOINT } from "../../utils";
import { CapacitorContext } from "../../context/CapacitorContext";
import { UserContext } from "../../context/UserContext";

const Navbar = () => {
  const { user } = useContext(UserContext);

  const { device, platform, setupStatusBar } = useContext(CapacitorContext);

  useEffect(() => {
    if (platform !== null && platform !== "web") {
      setupStatusBar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  return (
    <nav
      id="navbar"
      className={"navbar navbar-dark pb-2 px-2"}
      style={{
        paddingTop:
          platform === "ios" && hasNotch(device)
            ? 75
            : platform !== "web"
            ? 25
            : 10,
      }}
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <img
            alt="MUSCLE FACTORY Logo"
            id="navbar-logo"
            src={`${S3_ENDPOINT}/icono-blanco.png`}
            className="logo-navbar"
          />
        </a>
        <div className="ms-auto text-right py-2">
          <div>
            {user === null && (
              <Link to="/entrar" className="btn btn-light me-2">
                <i className="fa fa-user-circle"></i>
              </Link>
            )}
            <Link to="/myfactory" className="btn btn-primary">
              Acceder
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
