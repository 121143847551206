import React, { useState } from "react";

const SignUpForm = ({ handleSubmitSignUp, setLogin, spinner }) => {
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitSignUp(nombre, correo, password, telefono);
  };

  return (
    <div className="container-fluid ps-0 mb-4">
      <h3>Crea tu cuenta</h3>
      <form className="card p-3 no-scale" onSubmit={handleSubmit}>
        <label>Nombre</label>
        <input
          type="text"
          className="form-control mb-3"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
        />
        <label>Correo</label>
        <input
          type="email"
          className="form-control mb-3"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
        />
        <label>Contraseña</label>
        <input
          type="password"
          className="form-control mb-3"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label>Teléfono</label>
        <input
          type="tel"
          className="form-control mb-3"
          value={telefono}
          onChange={(e) => setTelefono(e.target.value)}
        />
        <div className="row mb-2 align-items-center">
          <div className="col-12 col-md-6 text-center">
            <button type="submit" className="btn btn-primary w-100 mt-3">
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                "Crear cuenta"
              )}
            </button>
          </div>
          <div className="col-12 col-md-6 text-center">
            <p className="mt-3 small mb-0">
              ¿Ya tienes cuenta?{" "}
              <button
                type="button"
                className="btn btn-sm btn-link d-inline px-0 py-0 text-white"
                onClick={() => setLogin(true)}
              >
                <span>Inicia Sesión</span>
              </button>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
