import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { Link, navigate } from "@reach/router";
import { S3_ENDPOINT } from "../utils";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, spinner, signIn } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (user !== null) {
      navigate("/myfactory");
    }
  }, [user]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 bg-black vh-100 px-0 hide-mobile">
          <div className="bg-vertical"></div>
        </div>
        <div className="col-12 col-md-6 bg-camo vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <div className="login-card p-3 text-center">
                <img
                  src={`${S3_ENDPOINT}/logo-blanco.png`}
                  className="w-100 mb-4 cursor-pointer"
                  alt="MUSCLE FACTORY Logo"
                  onClick={() => navigate("/")}
                />
                <h1 className="text-white my-4">Entrar</h1>
              </div>
              <div className="card login-card border bg-gray text-white no-scale text-left shadow p-4">
                <form onSubmit={handleSubmit}>
                  <label>Correo Electrónico</label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>
                    Contraseña{" "}
                    <button
                      type="button"
                      className="btn btn-link text-muted text-left text-small py-0 mb-1"
                      onClick={(e) => {
                        e.preventDefault();
                        togglePassword();
                      }}
                    >
                      <span className="text-montserrat text-small text-auto">
                        {showPassword ? "Ocultar" : "Mostrar"}
                      </span>
                    </button>
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control mb-3"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    value="Entrar"
                  >
                    {spinner ? (
                      <div className="spinner-border"></div>
                    ) : (
                      "Log In"
                    )}
                  </button>
                </form>
                <div className="container-fluid px-0 mt-4">
                  ¿Aun no tienes cuenta?{" "}
                  <Link to="/registro" className="text-accent">
                    Crea tu cuenta
                  </Link>
                </div>
                <div className="container-fluid px-0 mt-4">
                  <Link to="/recuperar" className="text-muted">
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
