import React, { useContext } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import { UserContext } from "../../context/UserContext";

const CheckoutDiscount = ({ class_package_id }) => {
  const { user } = useContext(UserContext);

  const { discountCode, setDiscountCode, spinnerDescuento, validarDescuento } =
    useContext(CheckoutContext);

  const handleSubmitDescuento = (e) => {
    e.preventDefault();
    validarDescuento(discountCode, class_package_id);
  };

  const renderDescuento = () => {
    if (user !== null) {
      return (
        <form className="card" onSubmit={handleSubmitDescuento}>
          <div>
            <label className="bold mb-1">Código de Descuento</label>
            <input
              type="text"
              className="form-control w-50 mb-3"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <button
              type="submit"
              className="btn btn-outline-light"
              disabled={spinnerDescuento}
            >
              {spinnerDescuento ? (
                <div className="spinner-border"></div>
              ) : (
                "Aplicar"
              )}
            </button>
          </div>
        </form>
      );
    }
  };

  return <div className="mb-3">{renderDescuento()}</div>;
};

export default CheckoutDiscount;
